import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import querystring from 'query-string'
import { Form, Table, Button, Drawer, Input, Modal, Tooltip, Icon, message } from 'antd'
import { getCertificates, removeCertificate } from '../redux/certificate'
import CertificateType from './CertificateType'
import CertificateAlias from './CertificateAlias'
import CertificateDomains from './CertificateDomains'
import { CERTIFICATE_AUTH_MODE, CERTIFICATE_AUTH_MODE_DISPLAY_NAME, CERTIFICATE_BRAND, CERTIFICATE_VERSION_STATUS } from '../utils/constant'
import EditCertificateConfig from './EditCertificateConfig'

import sectigoImg from '../assets/svg/sectigo.svg'
import letsenryptImg from '../assets/svg/letsencrypt.svg'

class MyCertificates extends React.Component {
  constructor (props) {
    super(props)
    let {search, offset, limit, sortKey, sortOrder} = querystring.parse(this.props.location.search)
    search = search || ''
    offset = parseInt(offset || 0)
    limit = parseInt(limit || 10)
    sortKey = sortKey || ''
    sortOrder = sortOrder || ''

    const current = parseInt(offset / limit) + 1

    this.state = {
      firstLoading: true,
      searchContent: search,
      current,
      offset,
      limit,
      sortKey,
      sortOrder,
      activeCertificateId: null,
      editCertificateConfigDrawerVisible: false
    }
    this.searchCertificates()
  }

  componentWillUnmount () {
    this.isUnmounted = true
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  refreshUrl = ({searchContent, offset, limit, sortKey, sortOrder}) => {
    const curUrl = new URL(window.location.href)
    curUrl.searchParams.set('search', searchContent)
    curUrl.searchParams.set('offset', offset)
    curUrl.searchParams.set('limit', limit)
    curUrl.searchParams.set('sortKey', sortKey)
    curUrl.searchParams.set('sortOrder', sortOrder)
    this.props.history.replace(curUrl.pathname + curUrl.search)
  }

  searchCertificates = (state) => {
    const {searchContent, offset, limit, sortKey, sortOrder} = Object.assign({}, this.state, state)

    this.refreshUrl({searchContent, offset, limit, sortKey, sortOrder})

    this.props.dispatch(getCertificates({searchContent, offset, limit, sortKey, sortOrder})).then((response) => {
      this.setState({
        firstLoading: false
      })
      if (response.payload && response.payload.rows.length > 0) {
        if (response.payload.rows.some(item => item.latestUpdateStatus === CERTIFICATE_VERSION_STATUS.QUEUING || item.latestUpdateStatus === CERTIFICATE_VERSION_STATUS.GENERATING)) {
          if (this.timer || this.isUnmounted) {
            return
          }
          this.timer = setInterval(() => {
            this.searchCertificates()
          }, 3 * 1000)
        } else {
          if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
          }
        }
      }
    })
  }

  get tableColumns () {
    const {firstLoading, sortKey, sortOrder} = this.state;
    const columns = [{
      title: '证书ID/名称',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <>
            <Button onClick={() => this.props.history.push(`/monitor/certificates/${record.id}`)} type='link' style={{paddingLeft: '0px', paddingRight: '0px'}}>{record.name}</Button>
            <br/>
            <CertificateAlias key={record.id} certificateId={record.id} certificateAlias={record.alias || record.name} onChangeComplete={this.searchCertificates}/>
          </>
        )
      }
    }, {
      title: '类型',
      dataIndex: 'type',
      sorter: true,
      render: (text, record) => {
        return <CertificateType type={record.type}/>
      }
    }, {
      title: '关联域名',
      dataIndex: 'domains',
      render: (text, record) => {
        return <CertificateDomains domains={record.domains} onlyFirst={true} maxWidth={235}/>
      }
    }, {
      title: '证书品牌',
      dataIndex: 'brand',
      sorter: true,
      render: (brand, record) => {
        if (brand === CERTIFICATE_BRAND.SECTIGO) {
          return <img height={10} src={sectigoImg} alt="Sectigo" />
        } else {
          return <img height={22} style={{ marginLeft: '-2px', marginTop: '-9px' }} src={letsenryptImg} alt="Let'sencrypt" />
        }
      }
    }, {
      title: '验证模式',
      dataIndex: 'authMode',
      sorter: true,
      render: (text, record) => {
        return CERTIFICATE_AUTH_MODE_DISPLAY_NAME[text]
      }
    }, {
      title: '创建时间',
      dataIndex: 'createTime',
      sorter: true,
      render: createTime => moment(createTime).format('YYYY-MM-DD HH:mm')
    }, {
      title: '更新次数',
      dataIndex: 'versionsCount',
    }, {
      title: '最后更新',
      dataIndex: 'latestUpdateTime',
      sorter: true,
      render: (text, record) => {
        if (record.latestUpdateStatus === CERTIFICATE_VERSION_STATUS.QUEUING) {
          let queueReason = ''
          if (record.latestUpdateIsAuto) {
            queueReason = '为避免因系统创建的自动更新任务并发过高而触发证书颁发机构的接口限流，所以需要排队进行更新，自动更新任务预计将在数小时内完成，请您耐心等待'
          } else if (record.authMode === CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE) {
            queueReason = '对于单个用户来说，所有通过免DNS授权方式申请的证书，其验证域名所有权指向的是同一个CNAME解析，当使用该方式并发申请或者更新证书时，会导致该CNAME解析最终指向的TXT类型解析记录过多，导致证书颁发机构验证域名所有权失败，所以需要排队进行申请或更新，请您耐心等待'
          }
          return (
            <Tooltip title={queueReason}>
              <span style={{color: 'orange'}}>排队中&nbsp;<Icon type="clock-circle" /></span>
              <br/>
              <span>{moment(record.latestUpdateTime).format('YYYY-MM-DD HH:mm')}</span>
            </Tooltip>
          )
        } else if (record.latestUpdateStatus === CERTIFICATE_VERSION_STATUS.GENERATING) {
          return (
            <Tooltip title={'证书更新任务执行中，预计需要 3 ～ 15 分钟，请您耐心等待'}>
              <span style={{color: '#1890ff'}}>更新中&nbsp;<Icon type="loading" /></span>
              <br/>
              <span>{moment(record.latestUpdateTime).format('YYYY-MM-DD HH:mm')}</span>
            </Tooltip>
          )
        } else if (record.latestUpdateStatus === CERTIFICATE_VERSION_STATUS.GENERATED) {
          return (
            <div>
              <span style={{color: 'green'}}>已更新</span>
              <br/>
              <span>{moment(record.latestUpdateTime).format('YYYY-MM-DD HH:mm')}</span>
            </div>
          )
        } else if (record.latestUpdateStatus === CERTIFICATE_VERSION_STATUS.REVOKED) {
          return (
            <div>
              <span style={{color: 'red'}}>已吊销</span>
              <br/>
              <span>{moment(record.latestUpdateTime).format('YYYY-MM-DD HH:mm')}</span>
            </div>
          )
        } else if (record.latestUpdateStatus === CERTIFICATE_VERSION_STATUS.ERROR) {
          return (
            <div>
              <span style={{color: 'red'}}>已失败</span>
              <br/>
              <span>{moment(record.latestUpdateTime).format('YYYY-MM-DD HH:mm')}</span>
            </div>
          )
        }
      }
    }, {
      title: '过期时间',
      dataIndex: 'expiredTime',
      sorter: true,
      render: (text, record) => {
        if (!record.expiredTime) {
          return '-'
        }

        if (new Date(record.expiredTime).getTime() <= Date.now()) {
          return (
            <div>
              <span style={{color: 'red'}}>已过期</span>
              <br/>
              <span>{moment(record.expiredTime).format('YYYY-MM-DD HH:mm')}</span>
            </div>
          )
        } else {
          return (
            <div>
              <span style={{color: 'green'}}>生效中·{moment(record.expiredTime).diff(moment(), 'days')}天后过期</span>
              <br/>
              <span>{moment(record.expiredTime).format('YYYY-MM-DD HH:mm')}</span>
            </div>
          )
        }
      }
    }, {
      title: '操作',
      dataIndex: 'action',
      align: 'right',
      fixed: 'right',
      render: (text, record) => {
        return (
          <>
            <Button onClick={this.handleClickCertificate.bind(this, record.id)} size='small' type='link'>查看</Button>
            |
            <Button onClick={this.handleEditCertificateConfig.bind(this, record.id)} size='small' type='link'>配置</Button>
            |
            <Button onClick={this.handleClickDelete.bind(this, record.id)} size='small' type='link'>删除</Button>
          </>
        )
      }
    }]

    if (firstLoading) {
      for(const column of columns) {
        if (column.dataIndex === sortKey) {
          column.defaultSortOrder = sortOrder
        }
      }
    }

    return columns
  }

  get tableData () {
    return [...(new Array(this.state.offset)), ...this.props.certificates.rows.map((item, index) => {
      item.key = index + this.state.offset + 1
      return item
    })]
  }

  handleCreateCertificate = () => {
    this.props.history.push('/guide/createcertificate')
  }

  handleEditCertificateConfig = (certificateId, e) => {
    e.preventDefault()
    e.stopPropagation()

    this.setState({
      activeCertificateId: certificateId,
      editCertificateConfigDrawerVisible: true
    })
  }

  handleEditCertificateConfigDrawerClose = () => {
    this.setState({
      editCertificateConfigDrawerVisible: false
    })
  }

  handleClickCertificate = (certificateId, e) => {
    e.preventDefault()
    this.props.history.push(`/monitor/certificates/${certificateId}`)
  }

  handleClickDelete = (certificateId, e) => {
    e.preventDefault()
    e.stopPropagation()

    Modal.confirm({
      title: '确认要删除该证书吗?',
      content: `证书删除后不可恢复，请谨慎操作`,
      okType: 'primary',
      okButtonProps: {size: 'small'},
      cancelButtonProps: {size: 'small'},
      autoFocusButton: false,
      maskClosable: true,
      onOk: () => {
        this.props.dispatch(removeCertificate({certificateId})).then((result) => {
          if (result.errorMsg) {
            return message.error(result.errorMsg)
          } else {
            message.success('删除证书成功')
    
            this.searchCertificates()
          }
        })
      },
      onCancel: () => {
      }
    })
  }

  handleEditCertificateConfigComplete = () => {
    this.setState({
      editCertificateConfigDrawerVisible: false
    })
  }

  handleChangeSearchContent = (e) => {
    this.setState({
      searchContent: e.target.value
    })
  }

  handleClickSearch = () => {
    this.setState({
      current: 1,
      offset: 0
    })
    this.searchCertificates({offset: 0})
  }

  handleChangeTable = (pagination, filters, sorter) => {
    const {current, pageSize} = pagination
    const offset = pageSize * (current - 1)
    const limit = pageSize
    const {field, order} = sorter
    const sortOrder = order || ''
    const sortKey = order ? field : ''
    this.setState({
      current,
      offset,
      limit,
      sortKey,
      sortOrder
    })
    this.searchCertificates({offset, limit, sortKey, sortOrder})
  }

  renderEditCertificatTargetDrawer = () => {
    // 这么写是为了每次显示Drawer都重新创建Drawer内的组件，解决Drawer内组件内容不更新问题
    const {editCertificateConfigDrawerVisible, activeCertificateId} = this.state
    if (!editCertificateConfigDrawerVisible) {
      return
    }

    return (
      <Drawer
          title='证书配置'
          placement='right'
          closable={false}
          width = {800}
          onClose={this.handleEditCertificateConfigDrawerClose}
          visible={editCertificateConfigDrawerVisible}
        >
          <EditCertificateConfig certificateId={activeCertificateId} onEditComplete={this.handleEditCertificateConfigComplete}/>
      </Drawer>
    )
  }

  render () {
    const {searchContent, firstLoading} = this.state
    return (
      <div className='content'>
        <Form layout='inline' style={{marginBottom: '12px'}}>
          <Form.Item>
            <Input.Search placeholder="输入关联域名或证书ID/名称搜索" onSearch={this.handleClickSearch} enterButton value={searchContent} onChange={this.handleChangeSearchContent} style={{width: '450px'}}/>
          </Form.Item>
          <Form.Item>
            <Button onClick={this.handleCreateCertificate} icon='plus'>创建证书</Button>
          </Form.Item>
        </Form>
        <Table
          columns={this.tableColumns}
          dataSource={firstLoading ? [] : this.tableData}
          size='small'
          scroll={{x: true}}
          onChange={this.handleChangeTable}
          pagination={{
            current: this.state.current,
            pageSize: this.state.limit,
            total: this.props.certificates.count,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showTotal: total => `共${total}条`
          }}
        />
        {this.renderEditCertificatTargetDrawer()}
      </div>
    )
  }
}

MyCertificates = connect((state) => {
  return {
    certificates: state.certificate.certificate.certificates
  }
})(MyCertificates)

export default MyCertificates
