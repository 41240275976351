import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Row, Col, Icon, Button, Radio, message} from 'antd'
import { createDnsServer } from '../redux/dns/server'
import { getDnsProviders } from '../redux/dns/provider'
import ConfigField from './ConfigField'
import { checkAccessJson, maskEmail } from '../utils'

class CreateDnsServer extends React.Component {
  static props = {
    onCreateComplete: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.state = {
      dnsProviderId: null,
      dnsServerAccessJson: null
    }

    this.props.dispatch(getDnsProviders())
  }

  get dnsProviderId() {
    const { dnsProviders } = this.props
    const { dnsProviderId } = this.state
    if (dnsProviderId) {
      return dnsProviderId
    } else {
      return dnsProviders[0].id
    }
  }

  handleChangeDnsProvider = (e) => {
    if (e.target.value === -1) {
      return
    }

    this.setState({
      dnsServerAccessJson: null,
      dnsProviderId: e.target.value
    })
  }

  handleEditDnsServer = (field, value) => {
    const accessJson = { ...(this.state.dnsServerAccessJson || {}) }
    accessJson[field] = value
    this.setState({
      dnsServerAccessJson: accessJson,
      dnsServerVerified: false
    })
  }

  handleClickCreate = () => {
    const {dnsServerAccessJson} = this.state
    const {dnsProviders, onCreateComplete} = this.props
    const dnsProviderId = this.dnsProviderId
    const {configJson} = dnsProviders.find(item => item.id === dnsProviderId)

    const error = checkAccessJson({providerConfigJson: configJson, serverAccessJson: dnsServerAccessJson})
    if (error) {
      return message.error(error)
    }

    this.props.dispatch(createDnsServer({dnsProviderId, accessJson: dnsServerAccessJson}))
      .then(response => {
        if (response.errorMsg) {
          return message.error(response.errorMsg)
        }

        message.success('DNS授权添加成功')
  
        if (onCreateComplete) {
          onCreateComplete()
        }
      })
  }

  render() {
    const { dnsProviders } = this.props
    if (!dnsProviders.length) {
      return <></>
    }

    const { dnsServerAccessJson } = this.state
    const dnsProviderId = this.dnsProviderId

    const dnsProvider = dnsProviders.find(item => item.id === dnsProviderId)
    const { fields } = dnsProvider.configJson

    const { userId, email } = this.props.me

    return (
      <div>
        <div style={{ marginTop: '20px' }}>
          <Radio.Group value={dnsProviderId} buttonStyle='solid' onChange={this.handleChangeDnsProvider}>
            {dnsProviders.filter(item => item.visible).map((item, index) => {
              if (item.configJson) {
                return (
                  <Radio.Button value={item.id} key={index}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <img src={item.logo} alt='' width={15} height={15} />&nbsp;{item.name}
                    </div>
                  </Radio.Button>
                )
              } else {
                return (
                  <Radio.Button value={item.id} key={index} disabled>
                    <img src={item.logo} alt='' width={20} height={20} />{item.name}&nbsp;<span style={{ fontSize: '12px', background: '#f50', color: 'white', borderRadius: '10px', lineHeight: '20px', height: '20px', padding: '1px 8px' }}>即将上线</span>
                  </Radio.Button>
                )
              }
            })}
            <Radio.Button value={-1} key={-1} style={{top: -3.5, backgroundColor: '#ffe58f'}}>
              <div style={{display: 'flex', alignItems: 'center', height: 30}} className='tucao'>
                <form method="post" action="https://support.qq.com/products/109240/topic-detail/2512/#label=popular" target='_blank' rel='noopener noreferrer'>
                  <input type="hidden" name="openid" value={userId}/>
                  <input type="hidden" name="nickname" value={maskEmail({email})}/>
                  <input type="hidden" name="avatar" value="/assets/images/avatar.png"/>
                  <button type='submit' style={{height: 30, lineHeight: '30px'}}>
                    <Icon type="bulb" theme="twoTone"/>&nbsp;我要提需求
                  </button>
                </form>
              </div>
            </Radio.Button>
          </Radio.Group>
          <div>
            {fields.map((item, index) => {
              const value = (dnsServerAccessJson || {})[item.field]
              return (
                <Row gutter={16} style={{ marginTop: '10px' }} type='flex' align='middle' key={index}>
                  <Col span={6}>
                    {item.name}
                  </Col>
                  <Col span={18}>
                    <ConfigField {...item} value={value} onChange={this.handleEditDnsServer} editable/>
                  </Col>
                </Row>
              )
            })}
          </div>
          <Row type='flex' justify='start' gutter={16} align='middle' style={{ marginTop: '10px' }}>
            <Col span={18}>
              <Icon type="info-circle" style={{ color: '#faad14' }} /><a style={{ color: '#faad14' }} href={dnsProvider.configJson.helpUrl} target='_blank' rel='noopener noreferrer'>&nbsp;点击查看如何获取DNS授权参数</a>
            </Col>
          </Row>
          <Row type='flex' justify='end' gutter={16}>
            <Col span={18} />
            <Col span={6}>
              <Button onClick={this.handleClickCreate} type='primary' block>添加授权</Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

CreateDnsServer = connect((state) => {
  return  {
    me: state.auth.me,
    dnsProviders: state.dns.provider.dnsProviders,
  }
})(CreateDnsServer)

export default CreateDnsServer
