import React from 'react'
import { connect } from 'react-redux'
import { Divider } from 'antd'

class BeansConsumeRules extends React.Component {
  render () {
    const {certificateVersionAutoUpdateUnitPrice, certificateVersionManuUpdateUnitPrice, certificateStatusAutoWatchUnitPrice, certificateStatusManuWatchUnitPrice, certificateVersionAutoPushUnitPrice, certificateVersionManuPushUnitPrice, certificatePrice: certificatePriceConfig} = this.props.configs
    if (!certificatePriceConfig) {
      return <></>
    }

    return (
      <div className='consume-rules'>
        <p className='service'>证书创建服务</p>
        <ul>
          <li className='rule-item'>
          Let'sEncrypt免费版证书:
            <ul>
              <li className='rule-sub-item'>单域名证书：<strong>免费</strong></li>
              <li className='rule-sub-item'>泛域名证书：<strong>免费</strong></li>
              <li className='rule-sub-item'>多域名证书：<strong>免费</strong></li>
            </ul>
          </li>
          <li className='rule-item'>
          Sectigo专业版证书:
            <ul>
              <li className='rule-sub-item'>单域名证书：<strong>{3980}&nbsp;余额</strong></li>
              <li className='rule-sub-item'>泛域名证书：<strong>{45800}&nbsp;余额</strong></li>
              <li className='rule-sub-item'>多域名证书：
                <ul>
                  <li className='rule-sub-item'>每个单域名：<strong>{3980}&nbsp;余额</strong></li>
                  <li className='rule-sub-item'>每个泛域名：<strong>{45800}&nbsp;余额</strong></li>
                  <li className='rule-sub-item'>域名数量不能少于3个</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <br/>
        <Divider/>
        <p className='service'>证书更新服务</p>
        <ul>
          <li className='rule-item'>
          Let'sEncrypt免费版证书:
            <ul>
              <li className='rule-sub-item'>更新任务（用户手动创建）：<strong>{certificateVersionManuUpdateUnitPrice}&nbsp;余额&nbsp;/&nbsp;次</strong></li>
              <li className='rule-sub-item'>更新任务（系统自动创建）：<strong>{certificateVersionAutoUpdateUnitPrice}&nbsp;余额&nbsp;/&nbsp;次</strong></li>
            </ul>
          </li>
          <li className='rule-item'>
          Sectigo专业版证书:
            <ul>
              <li className='rule-sub-item'>与证书创建服务费用相同</li>
            </ul>
          </li>
        </ul>
        <br/>
        <Divider/>
        <p className='service'>证书部署服务</p>
        <ul>
          <li className='rule-item'>
          部署至非容器类节点:
            <ul>
              <li className='rule-sub-item'>部署任务（用户手动创建）：<strong>{certificateVersionManuPushUnitPrice}&nbsp;余额&nbsp;/&nbsp;节点&nbsp;/&nbsp;次</strong></li>
              <li className='rule-sub-item'>部署任务（系统自动创建）：<strong>{certificateVersionAutoPushUnitPrice}&nbsp;余额&nbsp;/&nbsp;节点&nbsp;/&nbsp;次</strong></li>
            </ul>
          </li>
          <li className='rule-item'>
          部署至容器类节点:
            <ul>
              <li className='rule-sub-item'>部署任务（用户手动创建）：<strong>{certificateVersionManuPushUnitPrice}&nbsp;余额&nbsp;/&nbsp;容器&nbsp;/&nbsp;次</strong></li>
              <li className='rule-sub-item'>部署任务（系统自动创建）：<strong>{certificateVersionAutoPushUnitPrice}&nbsp;余额&nbsp;/&nbsp;容器&nbsp;/&nbsp;次</strong></li>
            </ul>
          </li>
        </ul>
        <br/>
        <Divider/>
        <p className='service'>证书监控服务</p>
        <ul className='rule-item'>
          <li className='rule-sub-item'>监控任务（用户手动创建）：<strong>{certificateStatusManuWatchUnitPrice}&nbsp;余额&nbsp;/&nbsp;次</strong></li>
          <li className='rule-sub-item'>监控任务（系统自动创建）：<strong>{certificateStatusAutoWatchUnitPrice}&nbsp;余额&nbsp;/&nbsp;次</strong></li>
        </ul>
        <br/>
        <Divider/>
        <p style={{color: 'orange', fontWeight: 'bold'}}>
          注：<br/>
          &nbsp;&nbsp;1RMB = 100 OHTTPS余额<br/>
          &nbsp;&nbsp;1USD = 700 OHTTPS余额
        </p>
      </div>
    )
  }
}

BeansConsumeRules = connect((state) => {
  return {
    configs: state.common.configs
  }
})(BeansConsumeRules)

export default BeansConsumeRules
