import React from 'react'
import PropTypes from 'prop-types'
import {Icon} from 'antd'
import { maskEmail } from '../utils'

class TuCao extends React.Component {
  static props = {
    userId: PropTypes.number,
    email: PropTypes.string
  }

  render () {
    const {userId, email} = this.props
    return (
      <div className='tucao'>
        <form method="post" action="https://support.qq.com/products/109240" target='_blank' rel='noopener noreferrer'>
          <input type="hidden" name="openid" value={userId}/>
          <input type="hidden" name="nickname" value={maskEmail({email})}/>
          <input type="hidden" name="avatar" value="/assets/images/avatar.png"/>
          <button type='submit'>
            <Icon type="smile"/>
            &nbsp;我要反馈
          </button>
        </form>
      </div>
    )
  }
}

export default TuCao
