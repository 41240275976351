import React from 'react'
import { Alert, Divider, Col, Row, Button, Modal, Icon, message, Drawer } from 'antd'
import {connect} from 'react-redux'
import { getBeansPods, createBeansPodOrder, getBeansPodOrder } from '../redux/bean'
import { getMyBeans } from '../redux/auth'
import { reportChargeQRClick, reportChargeQRExp } from '../utils/report'
import BeansConsumeRules from './BeansConsumeRules'

class Charge extends React.Component {
  constructor (props) {
    super(props)

    this.props.dispatch(getBeansPods())
    this.props.dispatch(getMyBeans())

    this.state = {
      modalVisible: false,
      beansConsumeRulesDrawerVisible: false
    }
  }

  componentWillUnmount () {
    this.isUnmounted = true
  }


  handleCreateCharge = (podId, podPrice) => {
    this.setState({
      selectedPodId: podId,
      selectedPodPrice: podPrice,
      transactionId: null,
      paymentProvider: null,
      qrCodeUrl: null,
      redirectUrl: null,
      modalVisible: true
    })

    reportChargeQRClick({podId, podPrice})

    this.props.dispatch(createBeansPodOrder({podId})).then((response) => {
      if (response.errorMsg) {
        this.setState({
          modalVisible: false
        })
        return message.error(response.errorMsg)
      }

      const {paymentProvider, transactionId, qrCodeUrl, redirectUrl} = response.payload
      this.setState({
        paymentProvider,
        qrCodeUrl,
        redirectUrl,
        transactionId
      })

      if (qrCodeUrl) {
        // 等待图片加载完成再显示
        const image = new Image()
        image.src = qrCodeUrl
        image.onload = () => {
          if (this.isUnmounted) {
            return
          }
          reportChargeQRExp({podId, podPrice})
        }
      } else if (redirectUrl) {
        this.setState({
          redirectUrl
        })
        window.open(redirectUrl, '_blank')
        reportChargeQRExp({podId, podPrice})
      }
    })
  }

  handleCompleteCharge = () => {
    const { transactionId } = this.state
    this.props.dispatch(getBeansPodOrder({orderId: transactionId})).then((response) => {
      if (response.errorMsg) {
        return
      }

      if (response.payload && response.payload.status === 'CONFIRMED') {
        this.props.dispatch(getMyBeans()).then(() => {
          message.success('充值成功')
        })
      }
    })

    this.setState({
      selectedPodId: null,
      selectedPodPrice: null,
      modalVisible: false,
      paymentProvider: null,
      qrCodeUrl: null,
      redirectUrl: null,
      transactionId: null,
    })
  }

  handleCancelCharge = () => {
    this.setState({
      selectedPodId: null,
      selectedPodPrice: null,
      modalVisible: false,
      paymentProvider: null,
      qrCodeUrl: null,
      redirectUrl: null,
    })
  }

  handleShowBeansConsumeRulesDrawer = () => {
    this.setState({
      beansConsumeRulesDrawerVisible: true
    })
  }

  handleBeansConsumeRulesDrawerClose = () => {
    this.setState({
      beansConsumeRulesDrawerVisible: false
    })
  }

  renderPods = () => {
    const {pods} = this.props

    if (!pods.length) {
      return
    }

    const rows = []
    for (let i = 0; i < pods.length; i += 3) {
      rows.push(
        <Row key={i} gutter={8} style={i === 0 ? {} : {marginTop: '10px'}}>
          {pods.slice(i, i + 3).map(item => {
            return (
              <Col span={8} key={item.id}>
                <Button className='pod' block onClick={this.handleCreateCharge.bind(this, item.id, item.price)}>
                  <p>{item.price}&nbsp;RMB</p>
                  <p style={{height: 24}}>{item.giftBeans > 0 ? 
                        <>
                          额外<span className='gift'>+{Math.round(item.giftBeans / item.beans * 100)}%</span>
                        </> : <>&nbsp;</>}
                  </p>
                  <p>{item.beans + item.giftBeans}&nbsp;余额</p>
                </Button>
              </Col>
            )
          })}
        </Row>
      )
    }

    return rows
  }

  renderChargeModal = () => {
    const {paymentProvider, qrCodeUrl, redirectUrl} = this.state
    if (!paymentProvider) {
      return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Icon type='loading'/>&nbsp;<span>支付订单生成中...</span></div>
      )
    }

    if (qrCodeUrl) {
      return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
          <img src={qrCodeUrl} height={180} width={180} alt='充值二维码'/>
          <div style={{marginTop: '2px', background: '#04BE02', color: 'white', width: '100%', textAlign: 'center'}}>请使用微信扫码支付</div>
        </div> 
      )
    } else if (redirectUrl) {
      return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column'}}>
          <div style={{display: 'flex', alignItems: 'center'}}><Icon type="info-circle" />&nbsp;请在新打开的页面中完成支付，支付完成前请不要关闭当前窗口</div>
          <div style={{marginTop: '10px', marginLeft: '16px'}}>如遇支付页面未自动打开，请点击<a href={redirectUrl} target='_blank' rel="noopener noreferrer" style={{textDecoration: 'underline'}}>此处链接</a>进行支付</div>
        </div>
      )
    }
  }

  render () {
    const {beans} = this.props
    const {selectedPodPrice, modalVisible} = this.state

    return (
      <>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div>我的余额：<strong>{beans}</strong></div>
          <Button type='link' onClick={this.handleShowBeansConsumeRulesDrawer} style={{padding: '0px'}} >查看余额消耗规则</Button>
        </div>
        <Divider/>
        {this.renderPods()}
        <p style={{color: 'orange', fontWeight: 'bold', marginTop: '8px', marginBottom: '-16px'}}>
          注：<br/>
          &nbsp;&nbsp;1RMB = 100 OHTTPS余额<br/>
          &nbsp;&nbsp;1USD = 700 OHTTPS余额
        </p>
        <Divider/>
        <Alert
          message="如充值后未到账请与客服联系"
          type="info"
          showIcon
        />
        <Modal
          title={<p style={{fontSize: '14px', fontWeight: 'bold', margin: '2px 0 0 0'}}>余额充值&nbsp;‧&nbsp;{selectedPodPrice}&nbsp;RMB</p>}
          closeIcon={<Icon type='close' style={{fontSize: '14px'}}/>}
          visible={modalVisible}
          okType='primary'
          okButtonProps= {{size: 'small'}}
          okText='已支付完成'
          cancelButtonProps= {{size: 'small'}}
          onCancel={this.handleCancelCharge}
          onOk={this.handleCompleteCharge}
          bodyStyle={{display: 'flex', justifyContent: 'center' }}
          width={ 400 }
          maskClosable={false}
          destroyOnClose={true}
          className='charge-modal'
        >
          {this.renderChargeModal()}
        </Modal>
        <Drawer
            title='余额消耗规则'
            placement='right'
            closable={false}
            width = {400}
            onClose={this.handleBeansConsumeRulesDrawerClose}
            visible={this.state.beansConsumeRulesDrawerVisible}
          >
            <BeansConsumeRules />
          </Drawer>
      </>
    )
  }
}

Charge = connect((state) => {
  return {
    beans: state.auth.beans,
    pods: state.bean.pods
  }
})(Charge)

export default Charge
