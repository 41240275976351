import { CERTIFICATE_TYPE, CONFIG_FIELD_TYPE } from './constant'

export const getCertificateTypeDesc = (type) => {
  switch(type) {
    case CERTIFICATE_TYPE.SINGLE:
      return '单域名'
    case CERTIFICATE_TYPE.MULTI:
      return '多域名'
    case CERTIFICATE_TYPE.WILDCARD:
      return '泛域名'
    default:
      return ''
  }
}

export const checkAccessJson = ({providerConfigJson, serverAccessJson}) => {
  serverAccessJson = Object.assign(providerConfigJson.default || {}, serverAccessJson)
  for (const {field, name, type, nullable, showWhen} of providerConfigJson.fields) {
    if (nullable) {
      continue
    }
    if (showWhen) {
      // $变量会在eval中使用
      // eslint-disable-next-line no-unused-vars
      const $ = serverAccessJson
      // eslint-disable-next-line no-eval
      const visible = eval(showWhen)
      if (!visible) {
        continue
      }
    }
    if (!serverAccessJson[field] || !serverAccessJson[field].trim()) {
      switch (type) {
        case CONFIG_FIELD_TYPE.SELECT:
          return `请选择${name}`
        default:
          return `请输入${name}`
      }
    }
  }
}

export const maskEmail = ({email}) => {
  // 邮箱脱敏：邮箱@之前部分1/2的内容使用*代替
  const emailParts = email.split('@')
  const maskedEmail = emailParts[0].slice(0, parseInt(emailParts[0].length / 4)) + 
                      '*'.repeat(emailParts[0].length - parseInt(emailParts[0].length / 2)) + 
                      emailParts[0].slice(emailParts[0].length - parseInt(emailParts[0].length / 2) + parseInt(emailParts[0].length / 4)) + 
                      '@' +
                      emailParts[1]
  return maskedEmail
}

export const getHttpProxyNginxConfig = ({ domains, userCode, proxyUrl }) => {
  return '# 此处为Nginx服务端配置代码示例，仅供参考\n\n' + 
  domains.map(domain => {
    return `server {
    # 注意配置的必须是80端口（即HTTP请求，非443端口的HTTPS请求）
    listen  80;
    server_name  ${domain.toLowerCase().replace('*.', '')};

    location /.well-known/acme-challenge/ {
        # 设置代理请求头，用以验证证书申请者身份
        proxy_set_header  x-ohttps-user  ${userCode};

        # 设置代理地址，指向OHTTPS代理验证地址，以实现证书申请及后续更新的自动化
        proxy_pass  ${proxyUrl};
    }

    # ... 其它Nginx配置 ...
}`
  }).join('\n\n')
}
