import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router-dom'
import IndexLayout from './layouts/Index'
import MonitorLayout from './layouts/Monitor'
import GuideLayout from './layouts/Guide'
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import store from './redux/store'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import * as Sentry from '@sentry/react'

import './utils/proxy'

import './index.css'
import 'antd/dist/antd.css'
import 'react-perfect-scrollbar/dist/css/styles.css'

const history = createBrowserHistory()

Sentry.init({
  dsn: "https://11345a28eedc46b6aff31a8b604d717e@sen.intelli.ink/2",
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

window.sentry = Sentry

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zh_CN}>
      <Router history={history}>
        <Switch>
          <Route path='/monitor' render={props => <MonitorLayout {...props} />} />
          <Route path='/guide' render={props => <GuideLayout {...props} />} />
          <Route path='/' render={props => <IndexLayout {...props} />} />
        </Switch>
      </Router>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
)
