import React from 'react'
import PropTypes from 'prop-types'
import {CERTIFICATE_TYPE} from '../utils/constant'

class CertificateType extends React.Component {
  static props = {
    type: PropTypes.string
  }

  render () {
    const {type} = this.props

    if (type === CERTIFICATE_TYPE.SINGLE) {
      return (<span style={{color: '#87d068', border: '1px solid', padding: '0 1px'}}>单</span>)
    } else if (type === CERTIFICATE_TYPE.MULTI) {
      return (<span style={{color: '#2db7f5', border: '1px solid', padding: '0 1px'}}>多</span>)
    } else if (type === CERTIFICATE_TYPE.WILDCARD) {
      return (<span style={{color: '#108ee9', border: '1px solid', padding: '0 1px'}}>泛</span>)
    }
  }
}

export default CertificateType
