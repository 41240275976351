import React from 'react'
import {connect} from 'react-redux'
import {Row, Col, Icon, Input, Button, Collapse, message} from 'antd'
import Clipboard from 'react-clipboard.js'
import moment from 'moment'
import {getCertificateVersionDetail} from '../../redux/certificate/version'
import { CERTIFICATE_VERSION_STATUS } from '../../utils/constant'

class CertificateVersion extends React.Component {
  constructor (props) {
    super(props)

    this.searchCertificateVersionDetail()
  }

  searchCertificateVersionDetail = () => {
    this.props.dispatch(getCertificateVersionDetail({certificateId: this.certificateId, certificateVersionId: this.certificateVersionId}))
  }

  get certificateId () {
    return this.props.location.pathname.split('/').slice(-3, -2)[0]
  }

  get certificateVersionId () {
    return this.props.location.pathname.split('/').slice(-1)[0]
  }

  handleCopySuccess = (file) => {
    message.success(`证书文件 ${file} 复制成功`)
  }

  render () {
    const {certificateVersionDetail} = this.props
    if (!certificateVersionDetail) {
      return null
    }
    // 由于创建证书页面也会调用获取证书详情接口，所以certificateVersionDetail在访问该页面前可能已存在
    // 而导致取到的certificateVersionDetail不是最新的，状态是进行中，所以这里要避免这种情况，防止下方取值异常。
    if (certificateVersionDetail.status !== CERTIFICATE_VERSION_STATUS.GENERATED
        && certificateVersionDetail.status !== CERTIFICATE_VERSION_STATUS.ERROR
        && certificateVersionDetail.status !== CERTIFICATE_VERSION_STATUS.REVOKED) {
      return null
    }

    return (
      <div className='detail'>
        <div>
          <p className='header'><Icon type="file-sync" /><Button type='link' onClick={() => {this.props.history.push(`/monitor/certificates/${certificateVersionDetail.certificateId}`)}}>{certificateVersionDetail.certificate.name}</Button>@{moment(certificateVersionDetail.createTime).format('YYYY-MM-DD HH:mm')}</p>
          {certificateVersionDetail.error ?
            <>
              <Row type='flex' align='middle' style={{marginTop: '10px'}}>
                <Col span={2}>失败信息：</Col>
                <Col span={22}>{certificateVersionDetail.error}</Col>
              </Row>
            </> :
            <>
              <Row style={{ marginTop: '20px' }}>
              <Col span={24}>
                <Collapse
                  bordered={false}
                >
                  <Collapse.Panel
                    header={<span>文件(<strong>PEM格式</strong>)：&nbsp;<strong>cert.key</strong>&nbsp;<span style={{position: 'relative'}}>- 私钥文件<p style={{position: 'absolute', top: '-6px', right: '-30px', transform: 'scale(0.8)', background: '#f50', color: 'white', height: '19px', lineHeight: '16px', padding: '2px 3px'}}>推荐</p></span></span>}
                    key="1"
                    extra={
                      <div onClick={(e) => e.stopPropagation()}>
                        <Clipboard 
                          className='ant-btn' 
                          data-clipboard-text={certificateVersionDetail.detail.certKey} 
                          onSuccess={this.handleCopySuccess.bind(this, 'cert.key')}
                          style={{marginTop: '-5px'}}
                        >
                          <Icon type='copy'/>&nbsp;点击复制
                        </Clipboard>
                        <Button
                          style={{marginTop: '-5px', marginLeft:'5px'}}
                          onClick={() => this.certKeyFileDownload.click()}>
                          <Icon type='download'/>
                          <a
                            style={{display: 'none'}}
                            download='cert.key'
                            href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.certKey]))}
                            ref={(ref) => this.certKeyFileDownload = ref}>
                          </a>
                          &nbsp;点击下载.key后缀私钥文件
                        </Button>
                        <Button
                          style={{marginTop: '-5px', marginLeft:'5px'}}
                          onClick={() => this.certKeyPEMFileDownload.click()}>
                          <Icon type='download'/>
                          <a
                            style={{display: 'none'}}
                            download='certkey.pem'
                            href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.certKey]))}
                            ref={(ref) => this.certKeyPEMFileDownload = ref}>
                          </a>
                          &nbsp;点击下载.pem后缀私钥文件
                        </Button>
                      </div>
                    }
                  >
                    <Input.TextArea value={certificateVersionDetail.detail.certKey} autoSize className='cert-file'/>
                  </Collapse.Panel>
                  <Collapse.Panel
                    header={<span>文件(<strong>PEM格式</strong>)：&nbsp;<strong>cert.cer</strong>&nbsp;<span style={{position: 'relative'}}>- 证书文件(仅包含证书，部分浏览器会有兼容性问题)<p style={{position: 'absolute', top: '-6px', right: '-40px', transform: 'scale(0.8)', background: 'gray', color: 'white', height: '19px', lineHeight: '16px', padding: '2px 3px'}}>不推荐</p></span></span>}
                    key="2"
                    extra={
                      <div onClick={(e) => e.stopPropagation()}>
                        <Clipboard
                          className='ant-btn'
                          data-clipboard-text={certificateVersionDetail.detail.cert}
                          onSuccess={this.handleCopySuccess.bind(this, 'cert.cer')}
                          style={{marginTop: '-5px'}}
                        >
                          <Icon type='copy'/>&nbsp;点击复制
                        </Clipboard>
                        <Button
                          style={{marginTop: '-5px', marginLeft:'5px'}}
                          onClick={() => this.certFileDownload.click()}>
                          <Icon type='download'/>
                          <a
                            style={{display: 'none'}}
                            download='cert.cer'
                            href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.cert]))}
                            ref={(ref) => this.certFileDownload = ref}>
                          </a>
                          &nbsp;点击下载.cer后缀证书文件
                        </Button>
                        <Button
                          style={{marginTop: '-5px', marginLeft:'5px'}}
                          onClick={() => this.certPEMFileDownload.click()}>
                          <Icon type='download'/>
                          <a
                            style={{display: 'none'}}
                            download='cert.pem'
                            href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.cert]))}
                            ref={(ref) => this.certPEMFileDownload = ref}>
                          </a>
                          &nbsp;点击下载.pem后缀证书文件
                        </Button>
                      </div>
                    }
                  >
                    <Input.TextArea value={certificateVersionDetail.detail.cert} autoSize className='cert-file'/>
                  </Collapse.Panel>
                  <Collapse.Panel 
                    header={<span>文件(<strong>PEM格式</strong>)：&nbsp;<strong>fullchain.cer</strong>&nbsp;<span style={{position: 'relative'}}>- 证书文件(包含证书和中间证书)<p style={{position: 'absolute', top: '-6px', right: '-30px', transform: 'scale(0.8)', background: '#f50', color: 'white', height: '19px', lineHeight: '16px', padding: '2px 3px'}}>推荐</p></span></span>} 
                    key="3" 
                    extra={
                      <div onClick={(e) => e.stopPropagation()}>
                        <Clipboard
                          className='ant-btn' 
                          data-clipboard-text={certificateVersionDetail.detail.fullChainCerts} 
                          onSuccess={this.handleCopySuccess.bind(this, 'fullchain.cer')}
                          style={{marginTop: '-5px'}}
                        >
                          <Icon type='copy'/>&nbsp;点击复制
                        </Clipboard>
                        <Button
                          style={{marginTop: '-5px', marginLeft:'5px'}}
                          onClick={() => this.fullChainCertsFileDownload.click()}>
                          <Icon type='download'/>
                          <a 
                            style={{display: 'none'}} 
                            download='fullchain.cer'
                            href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.fullChainCerts]))}
                            ref={(ref) => this.fullChainCertsFileDownload = ref}>
                          </a>
                          &nbsp;点击下载.cer后缀证书文件
                        </Button>
                        <Button
                          style={{marginTop: '-5px', marginLeft:'5px'}}
                          onClick={() => this.fullChainCertsPEMFileDownload.click()}>
                          <Icon type='download'/>
                          <a 
                            style={{display: 'none'}} 
                            download='fullchain.pem'
                            href={URL.createObjectURL(new Blob([certificateVersionDetail.detail.fullChainCerts]))}
                            ref={(ref) => this.fullChainCertsPEMFileDownload = ref}>
                          </a>
                          &nbsp;点击下载.pem后缀证书文件
                        </Button>
                      </div>
                    }
                  >
                    <Input.TextArea value={certificateVersionDetail.detail.fullChainCerts} autoSize className='cert-file'/>
                  </Collapse.Panel>
                </Collapse>
              </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col span={24} style={{textAlign: 'right'}}>
              <Button type='link' href='/docs/certformat' target='_blank' rel='noopener noreferrer'>需要其他格式的证书文件？点击此出了解如何转换证书格式</Button>
                </Col>
              </Row>
            </>
          }
        </div>
      </div>
    )
  }
}

CertificateVersion = connect ((state) => {
  return {
    certificateVersionDetail: state.certificate.version.certificateVersionDetail
  }
})(CertificateVersion)

export default CertificateVersion
