import React from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import {Tabs, Row, Col, Button, Icon, Drawer, message, Modal, Tooltip} from 'antd'
import Clipboard from 'react-clipboard.js'
import CertificateType from '../../components/CertificateType'
import CertificateAlias from '../../components/CertificateAlias'
import CertificateDomains from '../../components/CertificateDomains'
import CertificateVersions from '../../components/CertificateVersions'
import CertificatePushes from '../../components/CertificatePushes'
import OptionsModal from '../../components/OptionsModal'
import { getCertificateDetail } from '../../redux/certificate'
import { createCertificateVersion } from '../../redux/certificate/version'
import { repushCertificate } from '../../redux/certificate/push'
import EditCertificateConfig from '../../components/EditCertificateConfig'
import DnsAliasAuthModeDnsRecords from '../../components/DnsAliasAuthModeDnsRecords'
import { CERTIFICATE_AUTH_MODE, CERTIFICATE_AUTH_MODE_DISPLAY_NAME, CERTIFICATE_BRAND } from '../../utils/constant'
import HttpAuthModeServerProxySettings from '../../components/HttpAuthModeServerProxySettings'

import sectigoImg from '../../assets/svg/sectigo.svg'
import letsenryptImg from '../../assets/svg/letsencrypt.svg'
import CreateCertificateVersionModal from '../../components/CreateCertificateVersionModal'

class CertificateDetail extends React.Component {
  constructor (props) {
    super(props)

    this.searchCertificateDetail()
    this.state = {
      tabActiveKey: 'versions',
      selectCloudServersModalVisible: false,
      createCertificateVersionModalVisible: false,
      editCertificateConfigDrawerVisible: false,
      dnsAliasAuthModeDnsRecordsDrawerVisible: false,
      httpAuthModeServerProxySettingsVisibile: false
    }
  }

  searchCertificateDetail = () => {
    const certificateId = this.certificateId
    this.props.dispatch(getCertificateDetail({certificateId}))
  }

  get certificateId () {
    return this.props.location.pathname.split('/').slice(-1)[0]
  }

  handleChangeTab = (activeKey) => {
    this.setState({
      tabActiveKey: activeKey
    })
  }

  handleClickRenewCertificate = () => {
    if (this.certificateVersionsCtl && this.certificateVersionsCtl.hasCertificateVersionInGenerating()) {
      return message.warn('请等待上一个证书生成或更新任务结束')
    }

    // Sectigo的证书使用免授权模式时更新需要重新设置CNAME解析并验证
    const {certificateDetail: {brand, authMode}} = this.props
    if (brand === CERTIFICATE_BRAND.SECTIGO && authMode === CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE) {
      this.setState({
        createCertificateVersionModalVisible: true
      })
      return
    }

    return Modal.confirm({
      title: brand === CERTIFICATE_BRAND.SECTIGO ? '确认要更新证书吗? (更新证书将会重新进行扣费，证书新版本有效期为365天(自今日起)，请谨慎操作)' : '确认要更新证书吗?',
      content: '通过手动创建的证书更新任务，在证书更新成功后将不会进行自动部署，可通过部署证书功能进行部署',
      okType: 'primary',
      okButtonProps: {size: 'small'},
      cancelButtonProps: {size: 'small'},
      autoFocusButton: false,
      maskClosable: true,
      onOk: () => {
        const certificateId = this.certificateId
        this.props.dispatch(createCertificateVersion({certificateId, isForRetry: false})).then(result => {
          if (result.errorMsg) {
            return message.error(result.errorMsg)
          }

          if (this.certificateVersionsCtl) {
            this.certificateVersionsCtl.searchCertificateVersions()
          }

          message.success('证书生成任务已创建成功')
        })
      },
      onCancel: () => {
      },
    })
  }

  handleClickRepushCertificate = () => {
    if (!this.props.certificateDetail.targets.length) {
      return message.warning('您还未配置证书部署节点')
    }

    this.setState({
      selectCloudServersModalVisible: true
    })
  }

  handelSelectedCloudServersOk = (cloudServerIds) => {
    this.setState({
      selectCloudServersModalVisible: false
    })

    if (!cloudServerIds.length) {
      return
    }

    const certificateId = this.certificateId
    this.props.dispatch(repushCertificate({certificateId, cloudServerIds})).then(result => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      }
      
      if (this.certificatePushesCtl) {
        this.certificatePushesCtl.searchCertificatePushes()
      }

      return message.success('证书部署任务已创建成功')
    })
  }

  handelSelectedPushServersCancel = () => {
    this.setState({
      selectCloudServersModalVisible: false
    })
  }

  handelCreateCertificateVersionOk = () => {
    this.setState({
      createCertificateVersionModalVisible: false
    })

    // 更新证书版本列表
    if (this.certificateVersionsCtl) {
      this.certificateVersionsCtl.searchCertificateVersions()
    }
  }

  handelCreateCertificateVersionCancel = () => {
    this.setState({
      createCertificateVersionModalVisible: false
    })
  }

  handleEditCertificateConfig = (e) => {
    e.preventDefault()
    e.stopPropagation()

    this.setState({
      editCertificateConfigDrawerVisible: true
    })
  }

  handleEditCertificateConfigDrawerClose = () => {
    this.setState({
      editCertificateConfigDrawerVisible: false
    })
  }

  handleEditCertificateConfigComplete = () => {
    this.setState({
      editCertificateConfigDrawerVisible: false
    })
    this.searchCertificateDetail()
  }

  handleDnsAliasAuthModeDnsRecordsDrawerShow = () => {
    this.setState({
      dnsAliasAuthModeDnsRecordsDrawerVisible: true
    })
  }

  handleDnsAliasAuthModeDnsRecordsDrawerClose = () => {
    this.setState({
      dnsAliasAuthModeDnsRecordsDrawerVisible: false
    })
  }

  handleHttpAuthModeServerProxySettingsDrawerShow = () => {
    this.setState({
      httpAuthModeServerProxySettingsVisibile: true
    })
  }

  handleHttpAuthModeServerProxySettingsDrawerClose = () => {
    this.setState({
      httpAuthModeServerProxySettingsVisibile: false
    })
  }

  handleCopySuccess = () => {
    message.success('证书ID已复制到剪贴板')
  }

  initCertificateVersionsRef = (ref) => {
    this.certificateVersionsCtl = ref
  }

  initCertificatePushesRef = (ref) => {
    this.certificatePushesCtl = ref
  }

  renderSelectPushServersModal = () => {
    const {selectCloudServersModalVisible} = this.state
    if (!selectCloudServersModalVisible) {
      return
    }
  
    const {certificateDetail} = this.props

    const options = certificateDetail.targets.map(item => {
      item.label = item.name
      item.value = item.id
      item.disabled = !item.cloudPushPushable
      return item
    })

    return (
      <OptionsModal
        title='请选择需要部署的节点'
        visible={selectCloudServersModalVisible}
        options={options}
        onOk={this.handelSelectedCloudServersOk}
        onCancel={this.handelSelectedPushServersCancel}
        renderOption={(item) => {
          return (
            <Tooltip title={`${item.cloudProviderName} - ${item.cloudPushPosition} - ${item.alias || item.name}`} placement="bottomLeft">
              <div style={{display: 'flex', alignItems: 'center'}}>
                <img src={item.cloudProviderLogo} alt='' width={15} height={15}/>
                <span style={{display: 'inline-block', maxWidth: '416px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                  &nbsp;{item.cloudProviderName}&nbsp;-&nbsp;{item.cloudPushPosition}&nbsp;-&nbsp;{item.alias || item.name}
                  {item.disabled ? <span style={{color: "rgba(0, 0, 0, 0.35)"}}> (不支持主动推送) </span> : ''}
                </span>
              </div>
            </Tooltip>
          )
        }}
      />
    )
  }

  renderCreateCertificateVersionModal = () => {
    const {createCertificateVersionModalVisible} = this.state
    if (!createCertificateVersionModalVisible) {
      return
    }
  
    const {certificateDetail} = this.props
    return (
      <CreateCertificateVersionModal
        certificateDetail={certificateDetail}
        onOk={this.handelCreateCertificateVersionOk}
        onCancel={this.handelCreateCertificateVersionCancel}
      />
    )
  }

  renderTabBarExtraContent = () => {
    return (
      <> 
        <Button type='primary' size='small' style={{padding: '0 20px'}} onClick={this.handleClickRenewCertificate}>更新证书</Button>
        <Button type='primary' size='small' style={{padding: '0 20px', marginLeft: '20px'}} onClick={this.handleClickRepushCertificate}>部署证书</Button>
      </>
    )
  }

  renderEditCertificatTargetDrawer = () => {
    // 这么写是为了每次显示Drawer都重新创建Drawer内的组件，解决Drawer内组件内容不更新问题
    const {editCertificateConfigDrawerVisible} = this.state
    if (!editCertificateConfigDrawerVisible) {
      return
    }

    return (
      <Drawer
          title='证书配置'
          placement='right'
          closable={false}
          width = {800}
          onClose={this.handleEditCertificateConfigDrawerClose}
          visible={editCertificateConfigDrawerVisible}
        >
          <EditCertificateConfig certificateId={this.certificateId} onEditComplete={this.handleEditCertificateConfigComplete}/>
      </Drawer>
    )
  }

  renderDnsAliasAuthModeDnsRecordsDrawer = () => {
    // 这么写是为了每次显示Drawer都重新创建Drawer内的组件，解决Drawer内组件内容不更新问题
    const {dnsAliasAuthModeDnsRecordsDrawerVisible} = this.state
    if (!dnsAliasAuthModeDnsRecordsDrawerVisible) {
      return
    }
    const {certificateDetail} = this.props

    return (
      <Drawer
          title='免授权DNS解析记录配置'
          placement='right'
          closable={false}
          width = {800}
          onClose={this.handleDnsAliasAuthModeDnsRecordsDrawerClose}
          visible={dnsAliasAuthModeDnsRecordsDrawerVisible}
        >
          <DnsAliasAuthModeDnsRecords certificateDetail={certificateDetail} onClose={this.handleDnsAliasAuthModeDnsRecordsDrawerClose}/>
      </Drawer>
    )
  }

  renderHttpAuthModeServerProxySettingsDrawer = () => {
    // 这么写是为了每次显示Drawer都重新创建Drawer内的组件，解决Drawer内组件内容不更新问题
    const {httpAuthModeServerProxySettingsVisibile} = this.state
    if (!httpAuthModeServerProxySettingsVisibile) {
      return
    }
    const {certificateDetail} = this.props

    return (
      <Drawer
          title='服务端配置说明'
          placement='right'
          closable={false}
          width = {800}
          onClose={this.handleHttpAuthModeServerProxySettingsDrawerClose}
          visible={httpAuthModeServerProxySettingsVisibile}
        >
          <HttpAuthModeServerProxySettings domains={certificateDetail.domains} onClose={this.handleHttpAuthModeServerProxySettingsDrawerClose}/>
      </Drawer>
    )
  }

  renderEditConfigBtn = () => {
    return (
      <>
        <Button onClick={this.handleEditCertificateConfig} type='link' style={{height: '21px'}}><Icon type='setting'/></Button>
      </>
    )
  }

  renderAuthMode = () => {
    const {certificateDetail} = this.props

    if (certificateDetail.authMode === CERTIFICATE_AUTH_MODE.DNS_API_AUTH_MODE) {
      return (
        <>
          <Row type='flex' align='middle' style={{marginTop: '10px'}}>
            <Col span={2}>验证模式：</Col>
            <Col span={22}>
              <Button type="link" style={{paddingLeft: 0, height: 21}} onClick={() => this.props.history.push(`/monitor/dnsservers/${certificateDetail.dnsServer.id}`)}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  {CERTIFICATE_AUTH_MODE_DISPLAY_NAME[certificateDetail.authMode]} -&nbsp;
                  <img src={certificateDetail.dnsServer.dnsProviderLogo} alt='' width={15} height={15}/>
                  <span style={{display: 'inline-block', maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>  
                  &nbsp;{certificateDetail.dnsServer.dnsProviderName}&nbsp;-&nbsp;{certificateDetail.dnsServer.alias || certificateDetail.dnsServer.name}
                  </span>
                </div>
              </Button>
            </Col>
          </Row>
        </>
      )
    } else if (certificateDetail.authMode === CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE) {
      return (
        <>
          <Row type='flex' align='middle' style={{marginTop: '10px'}}>
            <Col span={2}>验证模式：</Col>
            <Col span={22}>
              <Button type="link" style={{paddingLeft: 0, height: 21}} onClick={this.handleDnsAliasAuthModeDnsRecordsDrawerShow}>
                {CERTIFICATE_AUTH_MODE_DISPLAY_NAME[certificateDetail.authMode]} - 点击查看DNS解析记录配置
              </Button>
            </Col>
          </Row>
        </>
      )
    } else if (certificateDetail.authMode === CERTIFICATE_AUTH_MODE.HTTP_AUTH_MODE) {
      return (
        <>
          <Row type='flex' align='middle' style={{marginTop: '10px'}}>
            <Col span={2}>验证模式：</Col>
            <Col span={22}>
              <Button type="link" style={{paddingLeft: 0, height: 21}} onClick={this.handleHttpAuthModeServerProxySettingsDrawerShow}>
                {CERTIFICATE_AUTH_MODE_DISPLAY_NAME[certificateDetail.authMode]} - 点击查看服务端配置说明
              </Button>
            </Col>
          </Row>
        </>
      )
    }
  } 

  render () {
    const {certificateDetail} = this.props

    if (!certificateDetail) {
      return null
    }

    return (
      <div className='detail'>
        <div>
          <p className='header'>
            <Icon type='audit'/>{certificateDetail.name}
            <Clipboard 
              className='ant-btn' 
              data-clipboard-text={certificateDetail.name} 
              onSuccess={this.handleCopySuccess}
              style={{marginLeft: '5px', border: 'none'}}
            >
              <Icon type='copy'/>
            </Clipboard>
          </p>
          <Row type='flex' align='middle'>
            <Col span={2}>证书名称：</Col>
            <Col span={6}><CertificateAlias key={certificateDetail.id} showIcon certificateId={certificateDetail.id} certificateAlias={certificateDetail.alias || certificateDetail.name} onChangeComplete={this.searchCertificateDetail}/></Col>
            <Col span={2}>创建时间：</Col>
            <Col span={10}>{moment(certificateDetail.createTime).format('YYYY-MM-DD HH:mm')}</Col>
          </Row>
          <Row type='flex' align='middle' style={{marginTop: '10px'}}>
            <Col span={2}>证书类型：</Col>
            <Col span={6}><CertificateType type={certificateDetail.type}/></Col>
            <Col span={2}>证书品牌：</Col>
            <Col span={10}>
              {
                certificateDetail.brand === CERTIFICATE_BRAND.SECTIGO ? <img height={10} src={sectigoImg} alt="Sectigo" /> : <img height={22} style={{ marginLeft: '-2px', marginTop: '-4px' }} src={letsenryptImg} alt="Let'sencrypt" />
              }
            </Col>
          </Row>
          <Row type='flex' align='middle' style={{marginTop: '10px'}}>
            <Col span={2}>关联域名：</Col>
            <Col span={22}><CertificateDomains domains={certificateDetail.domains} maxWidth={600}/></Col>
          </Row>
          {this.renderAuthMode()}
          <Row type='flex' align='middle' style={{marginTop: '10px'}}>
            <Col span={2}>自动通知：</Col>
            <Col span={22}>
              {certificateDetail.autoNotify ? <span style={{color: 'green'}}>已开启</span> : <span style={{color: 'red'}}>已关闭</span>}
              {this.renderEditConfigBtn()}
            </Col>
          </Row>
          <Row type='flex' align='middle' style={{marginTop: '10px'}}>
            <Col span={2}>自动更新：</Col>
            <Col span={22}>
              {
                certificateDetail.supportAutoUpdate ? 
                <>
                  {certificateDetail.autoUpdate ? <span style={{color: 'green'}}>已开启</span> : <span style={{color: 'red'}}>已关闭</span>}
                  {this.renderEditConfigBtn()}
                </> :
                <>
                  <span style={{color: 'gray'}}>不支持自动更新</span>
                </>
              }
            </Col>
          </Row>
          <Row type='flex' align='middle' style={{marginTop: '10px'}}>
            <Col span={2}>自动部署：</Col>
            <Col span={22}>
              {certificateDetail.autoPush ? <span style={{color: 'green'}}>已开启</span> : <span style={{color: 'red'}}>已关闭</span>}
              {this.renderEditConfigBtn()}
            </Col>
          </Row>
          <Row type='flex' align='middle' style={{marginTop: '10px', display: 'flex', alignItems: 'self-start'}}>
            <Col span={2}>部署节点：</Col>
            <Col span={22} style={{display: 'flex', flexWrap: 'wrap'}}>
              {certificateDetail.targets.length ? certificateDetail.targets.map(item => {
                return (
                  <Tooltip title={`${item.cloudProviderName} - ${item.cloudPushPosition} - ${item.alias || item.name}`} placement="bottomLeft" key={item.name} >
                    <Button type="link" style={{paddingLeft: 0, height: 21}} onClick={() => this.props.history.push(`/monitor/cloudservers/${item.id}`)}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <img src={item.cloudProviderLogo} alt='' width={15} height={15}/>
                        <span style={{display: 'inline-block', maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>  
                          &nbsp;{item.cloudProviderName}&nbsp;-&nbsp;{item.cloudPushPosition}&nbsp;-&nbsp;{item.alias || item.name}
                        </span>
                      </div>
                    </Button>
                  </Tooltip>
                )
              }) : <Button type='link' onClick={this.handleEditCertificateConfig} style={{padding: '0px', border: 'none', height: '21px'}}>点击开始配置</Button>}
              {this.renderEditConfigBtn()}
            </Col>
          </Row>
        </div>
        <Tabs style={{marginTop: '20px'}} onChange={this.handleChangeTab} tabBarExtraContent={this.renderTabBarExtraContent()}>
          <Tabs.TabPane tab="更新记录" key="versions">
            <CertificateVersions certificateId={certificateDetail.id} key={certificateDetail.id} initRef={this.initCertificateVersionsRef}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="部署记录" key="pushes">
            <CertificatePushes certificateId={certificateDetail.id} key={certificateDetail.id} initRef={this.initCertificatePushesRef}/>
          </Tabs.TabPane>
        </Tabs>
        {this.renderSelectPushServersModal()}
        {this.renderCreateCertificateVersionModal()}
        {this.renderEditCertificatTargetDrawer()}
        {this.renderDnsAliasAuthModeDnsRecordsDrawer()}
        {this.renderHttpAuthModeServerProxySettingsDrawer()}
      </div>
    )
  }
}

CertificateDetail = connect((state) => {
  return {
    certificateDetail: state.certificate.certificate.certificateDetail
  }
}) (CertificateDetail)

export default CertificateDetail
