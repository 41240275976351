import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Row, Col, Icon, Button, Radio, message} from 'antd'
import { createCloudServer } from '../redux/cloud/server'
import { getCloudProviders } from '../redux/cloud/provider'
import ConfigField from './ConfigField'
import { checkAccessJson } from '../utils'

class CreateCloudServer extends React.Component {
  static props = {
    onCreateComplete: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.state = {
      cloudId: null,
      cloudProviderId: null,
      cloudServerAccessJson: null
    }

    this.props.dispatch(getCloudProviders()).then((result) => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      }
      const cloudProviders = result.payload

      for (const cloud of cloudProviders) {
        for (const provider of cloud.providers) {
          if (provider.visible) {
            this.setState({
              cloudId: cloud.id,
              cloudProviderId: provider.id
            })
            return
          }
        }
      }
    })
  }

  get clouds () {
    const {cloudProviders} = this.props
    return cloudProviders.filter(item => item.providers.filter(item => item.visible).length > 0).map(item => {
      return {
        id: item.id,
        name: item.name,
        logo: item.logo
      }
    })
  }

  get providers () {
    const {cloudId} = this.state
    const {cloudProviders} = this.props
    const cloud = cloudProviders.find(item => item.id === cloudId)
    if (!cloud) {
      return []
    } else {
      return cloud.providers.filter(item => item.visible)
    }
  }

  get cloudProvider () {
    if (!this.state.cloudProviderId) {
      return null
    }

    for (const cloud of this.props.cloudProviders) {
      for (const provider of cloud.providers) {
        if (provider.id === this.state.cloudProviderId) {
          return {...provider, cloudName: cloud.name}
        }
      }
    }
    return null
  }

  handleChangeCloud = (e) => {
    for (const cloud of this.props.cloudProviders) {
      if (cloud.id === e.target.value) {
        this.setState({
          cloudId: e.target.value,
          cloudProviderId: cloud.providers[0].id,
          cloudServerAccessJson: null
        })
        return
      }
    }
  }

  handleChangeCloudProvider = (e) => {
    this.setState({
      cloudServerAccessJson: null,
      cloudProviderId: e.target.value
    })
  }

  handleEditCloudServer = (field, value) => {
    const accessJson = { ...(this.state.cloudServerAccessJson || {}) }
    accessJson[field] = value
    this.setState({
      cloudServerAccessJson: accessJson,
      cloudServerVerified: false
    })
  }

  handleClickCreate = () => {
    const {cloudProviderId, cloudServerAccessJson} = this.state
    const {onCreateComplete} = this.props
    const {configJson} = this.cloudProvider

    const error = checkAccessJson({providerConfigJson: configJson, serverAccessJson: cloudServerAccessJson})
    if (error) {
      return message.error(error)
    }

    this.props.dispatch(createCloudServer({cloudProviderId, accessJson: cloudServerAccessJson}))
      .then(response => {
        if (response.errorMsg) {
          return message.error(response.errorMsg)
        }

        message.success('部署节点添加成功')
  
        if (onCreateComplete) {
          onCreateComplete()
        }
      })
  }

  render() {
    const {cloudId, cloudProviderId, cloudServerAccessJson} = this.state
    if (!cloudProviderId) {
      return null
    }

    const currentCloudProvider = this.cloudProvider
    const configJson = currentCloudProvider.configJson

    return (
      <div>
        <div style={{ marginTop: '20px' }}>
          <Radio.Group value={cloudId} buttonStyle='solid' onChange={this.handleChangeCloud}>
            {this.clouds.map((item, index) => {
                return (
                  <Radio.Button value={item.id} key={index}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <img src={item.logo} alt='' width={15} height={15} />&nbsp;{item.name}
                    </div>
                  </Radio.Button>
                )
              })
            }
          </Radio.Group>
          <br/>
          <Radio.Group value={cloudProviderId} buttonStyle='solid' onChange={this.handleChangeCloudProvider} style={{marginTop: '10px'}}>
            {this.providers.map((item, index) => {
                return (
                  <Radio.Button value={item.id} key={index}>
                    <div style={{display: 'flex', alignItems: 'center'}}>{item.position}
                    </div>
                  </Radio.Button>
                )
              })
            }
          </Radio.Group>
          <div>
            {configJson.fields.map((item, index) => {
              const value = (cloudServerAccessJson || {})[item.field]
              return (
                <Row gutter={16} style={{ marginTop: '10px' }} type='flex' align='middle' key={index}>
                  <Col span={6}>
                    {item.name}
                  </Col>
                  <Col span={18}>
                    <ConfigField {...item} value={value} onChange={this.handleEditCloudServer} editable/>
                  </Col>
                </Row>
              )
            })}
          </div>
          <Row type='flex' justify='start' gutter={16} align='middle' style={{ marginTop: '10px' }}>
            <Col span={18}>
          <Icon type="info-circle" style={{ color: '#faad14' }} /><a style={{ color: '#faad14' }} href={configJson.helpUrl} target='_blank' rel='noopener noreferrer'>&nbsp;点击查看如何创建和使用【{currentCloudProvider.cloudName}-{currentCloudProvider.position}】类型部署节点</a>
            </Col>
          </Row>
          <Row type='flex' justify='end' gutter={16}>
            <Col span={18} />
            <Col span={6}>
              <Button onClick={this.handleClickCreate} type='primary' block>添加节点</Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

CreateCloudServer = connect((state) => {
  return  {
    cloudProviders: state.cloud.provider.cloudProviders,
  }
})(CreateCloudServer)

export default CreateCloudServer
