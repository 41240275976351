const SEND_EMAIL_ING = 'COMMON/SEND_EMAIL_ING'
const SEND_EMAIL_SUCCESS = 'COMMON/SEND_EMAIL_SUCCESS'
const SEND_EMAIL_FAILURE = 'COMMON/SEND_EMAIL_FAILURE'

const UNSCRIBE_EMAIL = 'COMMON/UNSCRIBE_EMAIL'
const UNSCRIBE_EMAIL_SUCCESS = 'COMMON/UNSCRIBE_EMAIL_SUCCESS'
const UNSCRIBE_EMAIL_FAILURE = 'COMMON/UNSCRIBE_EMAIL_FAILURE'

const GET_CONFIGS_ING = 'COMMON/GET_CONFIGS_ING'
const GET_CONFIGS_SUCCESS = 'COMMON/GET_CONFIGS_SUCCESS'
const GET_CONFIGS_FAILURE = 'COMMON/GET_CONFIGS_FAILURE'

const CHECK_DNS_CNAME_ING = 'COMMON/CHECK_DNS_CNAME_ING'
const CHECK_DNS_CNAME_SUCCESS = 'COMMON/CHECK_DNS_CNAME_SUCCESS'
const CHECK_DNS_CNAME_FAILURE = 'COMMON/CHECK_DNS_CNAME_FAILURE'

const CHECK_HTTP_PROXY_ING = 'COMMON/CHECK_HTTP_PROXY_ING'
const CHECK_HTTP_PROXY_SUCCESS = 'COMMON/CHECK_HTTP_PROXY_SUCCESS'
const CHECK_HTTP_PROXY_FAILURE = 'COMMON/CHECK_HTTP_PROXY_FAILURE'

const CHECK_CLOUD_SERVER_ING = 'COMMON/CHECK_CLOUD_SERVER_ING'
const CHECK_CLOUD_SERVER_SUCCESS = 'COMMON/CHECK_CLOUD_SERVER_SUCCESS'
const CHECK_CLOUD_SERVER_FAILURE = 'COMMON/CHECK_CLOUD_SERVER_FAILURE'

const CHECK_NOTIFICATION_TARGET_ING = 'COMMON/CHECK_NOTIFICATION_TARGET_ING'
const CHECK_NOTIFICATION_TARGET_SUCCESS = 'COMMON/CHECK_NOTIFICATION_TARGET_SUCCESS'
const CHECK_NOTIFICATION_TARGET_FAILURE = 'COMMON/CHECK_NOTIFICATION_TARGET_FAILURE'

const defaultState = {
  configs: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SEND_EMAIL_ING:
    case SEND_EMAIL_SUCCESS:
    case SEND_EMAIL_FAILURE:
      return {
        ...state
      }
    case GET_CONFIGS_SUCCESS:
      return {
        ...state,
        configs: action.payload
      }
    default:
      return {...state}
  }
}

export const sendEmail = ({email, templateId}) => {
  return {
    types: [SEND_EMAIL_ING, SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILURE],
    promise: (apiClient) => apiClient.post('/api/common/emails', {email, templateId})
  }
}

export const unsubscribeEmail = ({token}) => {
  return {
    types: [UNSCRIBE_EMAIL, UNSCRIBE_EMAIL_SUCCESS, UNSCRIBE_EMAIL_FAILURE],
    promise: (apiClient) => apiClient.post('/api/common/emails/unsubscribe', {token})
  }
}

export const getConfigs = () => {
  return {
    types: [GET_CONFIGS_ING, GET_CONFIGS_SUCCESS, GET_CONFIGS_FAILURE],
    promise: (apiClient) => apiClient.get('/api/common/configs')
  }
}

export const checkDnsCname = ({certificateType, certificateBrand, domains, certificateThirdOrderId}) => {
  return {
    types: [CHECK_DNS_CNAME_ING, CHECK_DNS_CNAME_SUCCESS, CHECK_DNS_CNAME_FAILURE],
    promise: (apiClient) => apiClient.post('/api/common/dns/cname/check', {certificateType, certificateBrand, domains, certificateThirdOrderId})
  }
}

export const checkHttpProxy = ({certificateType, domains}) => {
  return {
    types: [CHECK_HTTP_PROXY_ING, CHECK_HTTP_PROXY_SUCCESS, CHECK_HTTP_PROXY_FAILURE],
    promise: (apiClient) => apiClient.post('/api/common/http/proxy/check', {certificateType, domains})
  }
}

export const checkCloudServer = ({cloudProviderId, accessJson}) => {
  return {
    types: [CHECK_CLOUD_SERVER_ING, CHECK_CLOUD_SERVER_SUCCESS, CHECK_CLOUD_SERVER_FAILURE],
    promise: (apiClient) => apiClient.post('/api/common/cloud/server/check', {cloudProviderId, accessJson})
  }
}

export const checkNotificationTarget = ({notificationProviderId, accessJson}) => {
  return {
    types: [CHECK_NOTIFICATION_TARGET_ING, CHECK_NOTIFICATION_TARGET_SUCCESS, CHECK_NOTIFICATION_TARGET_FAILURE],
    promise: (apiClient) => apiClient.post('/api/common/notification/target/check', {notificationProviderId, accessJson})
  }
}
