import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Divider, Row, Col, Icon, Alert, Table, message } from 'antd'
import Clipboard from 'react-clipboard.js'
import { CERTIFICATE_BRAND } from '../utils/constant'

class DnsAliasAuthModeDnsRecords extends React.Component {
  static props = {
    certificateDetail: PropTypes.any,
    onClose: PropTypes.func
  }

  handleCopyAliasDomainRecordSuccess = () => {
    message.success('解析记录已成功复制到剪贴板')
  }

  handleCopyAliasDomainRecordValueSuccess = () => {
    message.success('解析记录值已成功复制到剪贴板')
  }

  handleClose = () => {
    const { onClose } = this.props
    onClose()
  }

  render() {
    const { certificateDetail: { dnsAliasRecords, brand } } = this.props

    const columnsHost = [
      {
        title: '主机记录',
        dataIndex: 'recordHost',
        ellipsis: true,
        render: (text, record) => {
          if (!text) {
            return  <>&nbsp;</>
          } else {
            return (
              <div className='record-value' style={{position: 'relative', paddingRight: '155px'}}>
                {text}
                <Clipboard className='ant-btn' style={{width: 150, position: 'absolute', top: 0, right: 0}} data-clipboard-text={record.subdomain} onSuccess={this.handleCopyAliasDomainRecordSuccess}>
                  <Icon type='copy'/>&nbsp;点击复制解析记录
                </Clipboard>
              </div>
            )
          }
        }
      }
    ]

    const columnsValue = [
      {
        title: '记录类型',
        dataIndex: 'recordType',
        width: 70
      },
      {
        title: '记录值',
        dataIndex: 'recordValue',
        ellipsis: true,
        render: text => {
          return (
            <div className='record-value' style={{paddingRight: '155px'}}>
              <span style={{overflow: 'hidden', wordBreak: 'break-word', whiteSpace: 'break-spaces'}}>
                {text}
              </span>
              <Clipboard className='ant-btn' style={{position: 'absolute', right: 0, top: 0, width: 150}} data-clipboard-text={text} onSuccess={this.handleCopyAliasDomainRecordValueSuccess}>
                <Icon type='copy' />&nbsp;点击复制解析记录值
              </Clipboard>
            </div>
          )
        }
      }
    ]

    const rowsHost = dnsAliasRecords.map(({ domain, subdomain }) => {
      return {
        domain,
        subdomain,
        recordHost: subdomain ? `${subdomain}.${domain}` : domain
      }
    })
    const rowsValue = [{
      recordType: dnsAliasRecords[0].type,
      recordValue: dnsAliasRecords[0].value
    }]

    return (
      <div>
        <div className='dns-records'>
          <Row type='flex' justify='start' gutter={16} align='middle'>
            <Col span={24}>
              <div>
                <Alert message={`请添加以下${rowsHost.filter(item => item.recordHost).length}条记录至您的DNS域名解析记录中`} type="info" showIcon />
                <Table
                  columns={columnsHost}
                  dataSource={rowsHost}
                  size='small'
                  pagination={false}
                  bordered
                  style={{ marginTop: '10px' }}
                  className='record-type-value'
                />
                <p style={{ marginTop: '10px' }}>以上域名解析记录的记录类型和记录值统一为：</p>
                <Table
                  columns={columnsValue}
                  dataSource={rowsValue}
                  size='small'
                  pagination={false}
                  bordered
                  style={{ marginTop: '-2px' }}
                  className='record-type-value'
                />
                <Alert message={brand === CERTIFICATE_BRAND.SECTIGO ? "请不要删除以上DNS域名解析记录，否则会导致相关证书生成或更新失败" : "请不要删除以上DNS域名解析记录，否则会导致相关证书生成失败或自动更新失败"} type="info" showIcon style={{ marginTop: '10px' }} />
                {brand === CERTIFICATE_BRAND.SECTIGO ? <Alert message={`对于Sectigo证书品牌，每次证书生成或更新任务所需添加的DNS域名解析记录不同`} type="info" showIcon style={{marginTop: '10px'}} /> : ''}
                {brand === CERTIFICATE_BRAND.SECTIGO ? <Alert message="免DNS授权模式暂不支持Sectigo证书的自动更新，如需证书的自动更新功能请使用DNS授权验证模式" type="info" showIcon style={{ marginTop: '10px' }} /> : ''}
              </div>
            </Col>
          </Row>
        </div>
        <Divider />
        <Row type='flex' justify='end' gutter={16}>
          <Col span={18} />
          <Col span={6}>
            <Button onClick={this.handleClose} type='primary' block>关闭</Button>
          </Col>
        </Row>
      </div>
    )
  }
}

DnsAliasAuthModeDnsRecords = connect((state) => {
  return {
    configs: state.common.configs,
    me: state.auth.me
  }
})(DnsAliasAuthModeDnsRecords)

export default DnsAliasAuthModeDnsRecords
